import { addMember, editMember } from "@/api/app";
import { validateMobile, validatePassword } from "@/libs/validate";
import uploadPicInput from "@/views/my-components/xboot/upload-pic-input";
import SetPassword from "@/views/my-components/xboot/set-password";
import mapLocate from "@/views/my-components/xboot/mapLocate";
export default {
  name: "user",
  components: {
    uploadPicInput,
    SetPassword,
    mapLocate
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    type: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      permissions: [],
      permissionList: [],
      visible: this.value,
      title: "",
      submitLoading: false,
      maxHeight: 510,
      form: {},
      formValidate: {
        // 表单验证规则
        nickname: [{
          required: true,
          message: "请输入昵称",
          trigger: "change"
        }],
        mobile: [{
          required: true,
          message: "请输入手机号",
          trigger: "change"
        }, {
          validator: validateMobile,
          trigger: "change"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "change"
        }, {
          validator: validatePassword,
          trigger: "change"
        }],
        email: [{
          type: "email",
          message: "邮箱格式不正确"
        }]
      }
    };
  },
  methods: {
    init() {},
    changeBirth(v, d) {
      this.form.birth = v;
    },
    changeVipStartTime(v, d) {
      this.form.vipStartTime = v;
    },
    changeVipEndTime(v, d) {
      this.form.vipEndTime = v;
    },
    createPerm(v) {
      this.permissionList.push(v);
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.permissions = this.permissions.join(",");
          if (typeof this.form.birth == "object") {
            this.form.birth = this.format(this.form.birth, "yyyy-MM-dd");
          }
          if (typeof this.form.vipStartTime == "object") {
            this.form.vipStartTime = this.format(this.form.vipStartTime, "yyyy-MM-dd HH:mm:ss");
          }
          if (typeof this.form.vipEndTime == "object") {
            this.form.vipEndTime = this.format(this.form.vipEndTime, "yyyy-MM-dd HH:mm:ss");
          }
          if (this.type == "1") {
            // 编辑
            this.submitLoading = true;
            editMember(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          } else {
            // 添加
            this.submitLoading = true;
            addMember(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          }
        }
      });
    },
    setCurrentValue(value) {
      if (value === this.visible) {
        return;
      }
      if (this.type == "1") {
        this.title = "编辑会员";
        this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
      } else if (this.type == "2") {
        this.title = "添加会员";
        this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
      } else {
        this.title = "会员详情";
        this.maxHeight = "100%";
      }
      // 清空数据
      this.$refs.form.resetFields();
      this.permissions = [];
      this.permissionList = [];
      if (this.type == "0" || this.type == "1") {
        // 回显数据
        let data = this.data;
        // 权限
        if (data.permissions) {
          this.permissions = data.permissions.split(",");
          this.permissionList = data.permissions.split(",");
        }
        // 回显
        this.form = data;
      } else {
        this.permissions = ["MEMBER"];
        this.permissionList = ["MEMBER"];
        // 添加
        this.form = {
          type: 0,
          grade: 0,
          vipStatus: 0,
          platform: -1
        };
      }
      this.visible = value;
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    },
    visible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.init();
  }
};