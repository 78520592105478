var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "user-edit"
  }, [_c('Drawer', {
    attrs: {
      "title": _vm.title,
      "width": "720",
      "draggable": "",
      "mask-closable": _vm.type == '0'
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('div', {
    staticClass: "drawer-content",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_c('div', {
    staticClass: "drawer-header"
  }, [_c('div', {
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_vm._v("基本信息")]), _c('Avatar', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '2',
      expression: "type != '2'"
    }],
    attrs: {
      "src": _vm.form.avatar,
      "size": "large"
    }
  })], 1), _c('Form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '2',
      expression: "type != '2'"
    }],
    attrs: {
      "label-colon": ""
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "用户名/UID"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.username) + " "), _c('Tooltip', {
    attrs: {
      "trigger": "hover",
      "placement": "right",
      "content": "账户已禁用"
    }
  }, [_c('Icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.status == -1,
      expression: "form.status == -1"
    }],
    staticStyle: {
      "margin-left": "10px",
      "cursor": "pointer"
    },
    attrs: {
      "type": "md-lock",
      "size": "18"
    }
  })], 1)], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "邀请码"
    }
  }, [_vm._v(_vm._s(_vm.form.inviteCode))])], 1)], 1)], 1), _c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.formValidate,
      "label-position": "top"
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "昵称",
      "prop": "nickname"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nickname", $$v);
      },
      expression: "form.nickname"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "手机号",
      "prop": "mobile"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobile", $$v);
      },
      expression: "form.mobile"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "邮箱",
      "prop": "email"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "性别"
    }
  }, [_c('Select', {
    attrs: {
      "transfer": ""
    },
    model: {
      value: _vm.form.sex,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sex", $$v);
      },
      expression: "form.sex"
    }
  }, [_c('Option', {
    attrs: {
      "value": "男"
    }
  }, [_vm._v("男")]), _c('Option', {
    attrs: {
      "value": "女"
    }
  }, [_vm._v("女")])], 1)], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    staticClass: "form-noheight",
    attrs: {
      "label": "头像"
    }
  }, [_c('upload-pic-input', {
    model: {
      value: _vm.form.avatar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "avatar", $$v);
      },
      expression: "form.avatar"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "生日"
    }
  }, [_c('DatePicker', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "type": "date",
      "transfer": ""
    },
    on: {
      "on-change": _vm.changeBirth
    },
    model: {
      value: _vm.form.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birth", $$v);
      },
      expression: "form.birth"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "地区"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "定位"
    }
  }, [_c('mapLocate', {
    attrs: {
      "id": "userLocate",
      "readonly": true,
      "clearable": false,
      "text": "查看地图",
      "preview": true
    },
    model: {
      value: _vm.form.position,
      callback: function ($$v) {
        _vm.$set(_vm.form, "position", $$v);
      },
      expression: "form.position"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "积分"
    }
  }, [_c('InputNumber', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "min": 0
    },
    model: {
      value: _vm.form.grade,
      callback: function ($$v) {
        _vm.$set(_vm.form, "grade", $$v);
      },
      expression: "form.grade"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "个人简介"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1), _c('Divider'), _c('p', {
    staticClass: "drawer-title"
  }, [_vm._v("VIP信息")]), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "用户类型"
    }
  }, [_c('Select', {
    attrs: {
      "transfer": "",
      "placeholder": "请选择"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('Option', {
    attrs: {
      "value": 0
    }
  }, [_vm._v("普通会员")]), _c('Option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("VIP")])], 1)], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "VIP状态"
    }
  }, [_c('Select', {
    attrs: {
      "transfer": "",
      "placeholder": "请选择"
    },
    model: {
      value: _vm.form.vipStatus,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vipStatus", $$v);
      },
      expression: "form.vipStatus"
    }
  }, [_c('Option', {
    attrs: {
      "value": 0
    }
  }, [_vm._v("未开通")]), _c('Option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("已开通")]), _c('Option', {
    attrs: {
      "value": 2
    }
  }, [_vm._v("已过期")])], 1)], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "VIP开通时间"
    }
  }, [_c('DatePicker', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "type": "datetime",
      "transfer": ""
    },
    on: {
      "on-change": _vm.changeVipStartTime
    },
    model: {
      value: _vm.form.vipStartTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vipStartTime", $$v);
      },
      expression: "form.vipStartTime"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "VIP到期时间"
    }
  }, [_c('DatePicker', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "type": "datetime",
      "transfer": ""
    },
    on: {
      "on-change": _vm.changeVipEndTime
    },
    model: {
      value: _vm.form.vipEndTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vipEndTime", $$v);
      },
      expression: "form.vipEndTime"
    }
  })], 1)], 1)], 1), _c('Divider'), _c('p', {
    staticClass: "drawer-title"
  }, [_vm._v("其他信息")]), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "角色权限（可直接输入回车添加）"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "filterable": "",
      "allow-create": "",
      "transfer": "",
      "placeholder": "请选择或输入添加"
    },
    on: {
      "on-create": _vm.createPerm
    },
    model: {
      value: _vm.permissions,
      callback: function ($$v) {
        _vm.permissions = $$v;
      },
      expression: "permissions"
    }
  }, _vm._l(_vm.permissionList, function (item, i) {
    return _c('Option', {
      key: i,
      attrs: {
        "value": item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "注册平台"
    }
  }, [_c('Select', {
    attrs: {
      "transfer": "",
      "placeholder": "请选择"
    },
    model: {
      value: _vm.form.platform,
      callback: function ($$v) {
        _vm.$set(_vm.form, "platform", $$v);
      },
      expression: "form.platform"
    }
  }, [_c('Option', {
    attrs: {
      "value": 0
    }
  }, [_vm._v("PC/H5")]), _c('Option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("Android")]), _c('Option', {
    attrs: {
      "value": 2
    }
  }, [_vm._v("IOS")]), _c('Option', {
    attrs: {
      "value": 3
    }
  }, [_vm._v("微信小程序")]), _c('Option', {
    attrs: {
      "value": 4
    }
  }, [_vm._v("支付宝小程序")]), _c('Option', {
    attrs: {
      "value": 5
    }
  }, [_vm._v("QQ小程序")]), _c('Option', {
    attrs: {
      "value": 6
    }
  }, [_vm._v("字节小程序")]), _c('Option', {
    attrs: {
      "value": 7
    }
  }, [_vm._v("百度小程序")]), _c('Option', {
    attrs: {
      "value": -1
    }
  }, [_vm._v("未知")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "height": "50px"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '0',
      expression: "type != '0'"
    }],
    staticClass: "drawer-footer br"
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _c('Button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };